import React,{Component} from "react"
import {connect} from "react-redux"

import UserList from "../../components/user-list/user-list"
import {getUserList} from "../../redux/actions"

class Boss extends Component{
    componentDidMount(){
        // this.props.getUserList('employee')
        // this.props.getUserList('boss')
        this.props.getUserList(this.props.user.friendList)
    }

    render(){
        console.log(this.props)
        var {user,userList} = this.props
        for(let i in userList){
            if(userList[i]._id == user._id){
                userList.splice(i,1)
                break
            }
        }
        return (
            <div>
                <UserList userList={this.props.userList}/>
            </div>
        )
    }
}

export default connect(
    sta => ({user: sta.user, userList: sta.userList}),
    {getUserList}
)(Boss)