import React,{Component} from "react"
import {connect} from "react-redux"

import UserList from "../../components/user-list/user-list"
import {getUserList} from "../../redux/actions"

class Employee extends Component{
    componentDidMount(){
        this.props.getUserList('boss')
    }

    render(){
        console.log(this.props)
        return (
            <div>
                <UserList userList={this.props.userList}/>
            </div>
        )
    }
}

export default connect(
    sta => ({userList: sta.userList}),
    {getUserList}
)(Employee)
