import React,{Component} from "react"
import {withRouter} from "react-router-dom"
import {TabBar} from "antd-mobile"
import PropTypes from "prop-types"

const {Item} = TabBar

class NavFooter extends Component{
    static propTypes = {
        navList: PropTypes.array.isRequired,
        unreadCount: PropTypes.number.isRequired
    }

    render(){
        let {navList, unreadCount} = this.props
        const path = this.props.location.pathname
        navList = navList.filter(nav=>!nav.hidden)
        console.log(navList)

        //(unreadCount > 0 ? unreadCount : 0)
        //unreadCount有小于0的情况（有收到新数据但客户端已断开ws连接，进入消息列表后触发所有消息已读，导致未读数量小于0）
        return (
            <TabBar id="navfooter">
                {navList.map(nav => (
                    <Item 
                        badge={nav.path=='/message'?unreadCount:0}
                        selected={path===nav.path}
                        icon={{uri:require(`./icon/${nav.icon}.png`)}}
                        selectedIcon={{uri:require(`./icon/${nav.icon}-selected.png`)}}
                        title={nav.text}
                        onPress={()=>this.props.history.replace(nav.path)}
                        key={nav.path}
                    />
                ))}
                
            </TabBar>
        )
    }
}

//向外暴露withRouter()包装产生的组件
//内部会向组件传入一些路由组件持有的属性：history/location/path
export default withRouter(NavFooter)