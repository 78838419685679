import React, {Component} from "react"
import {upload} from "../../api/index"

export default class Upload extends Component{

    niko = ()=>{
        var file = document.getElementById('hgg').files[0]

        let body = new FormData()
        body.append('ffgaa',file,file.name)
        body.append('niko','ahon')
        console.log(body.get('niko'))
        console.log(body)
        upload(body)
    }

    inputChange = (e)=>{
        console.log(e)
    }

    render(){
        return (
            <div>
                <input type="file" id="hgg" onChange={this.inputChange}/>
                <button onClick={this.niko}>upload</button>
            </div>
        )
    }
}