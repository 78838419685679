/*
主界面路由组件
路由页面一般包含redux操作，属于容器组件
*/
import React,{Component} from "react"
import {Route,Switch,Redirect} from "react-router-dom"
import {connect} from "react-redux"
import {NavBar,Icon,Badge} from "antd-mobile"
import {withRouter} from "react-router-dom"

import BossInfo from "../boss-info/boss-info"
import EmployeeInfo from "../employee-info/employee-info"
import Boss from "../boss/boss"
import Employee from "../employee/employee"
import Message from "../message/message"
import Personal from "../personal/personal"
import NavFooter from "../../components/nav-footer/nav-footer"
import Chat from "../chatlist/chatlist"
import AddFriend from "../addFriend/addFriend"
import {getUser} from "../../redux/actions"
import {getRedirection} from "../../utils/index"
import {refreshToken} from "../../api/index"


class Main extends Component{
    navList = [
        {
            path: '/boss',
            component: Boss,
            title: 'Friends list',
            icon: 'boss',
            text: 'Friends'
        },
        // {
        //     path: '/boss',
        //     component: Boss,
        //     title: 'Employees list',
        //     icon: 'employee',
        //     text: 'Employee'
        // },
        // {
        //     path: '/employee',
        //     component: Employee,
        //     title: 'Jobs list',
        //     icon: 'boss',
        //     text: 'Boss'
        // },
        {
            path: '/message',
            component: Message,
            title: 'Message list',
            icon: 'message',
            text: 'Message'
        },
        {
            path: '/personal',
            component: Personal,
            title: 'Personal config',
            icon: 'personal',
            text: 'Personal'
        }
    ]

    sendToken = async (RESEND)=>{       //之前用cookie检测登录状态，现在换成token，写了个异步函数，若redux不包含用户信息，则发送headers带token的请求，后端校验token有效则返回code==0，其他值则触发重定向
        var code = await this.props.getUser()   //用withRouter组件实现重定向（具体操作可以看nav-footer.jsx）
        var that = this
        console.log(code)
        console.log(this.props)
        console.log(getUser)

        if(code != 0){
            that.props.history.replace('/login')
        }

        // if(RESEND != 'RESEND'){         //未请求过重发token，可执行
        //     if(code != 0){
        //         // location.href = "/login"
        //         refreshToken().then(function(res){
        //             console.log(res)
        //             if(res.data.code == 0){        //请求新token成功，再发送一次获取用户信息请求
        //                 localStorage.setItem('loginToken',res.data.token)
        //                 localStorage.setItem('refreshToken',res.data.refreshToken)
        //                 that.sendToken('RESEND')
        //             }else{                          //请求新tokne失败，返回登录页面
        //                 that.props.history.replace('/login')
        //             }
        //         })
        //     }
        // }else{                          //已经请求过重发token，获取的token有问题，返回登录页面
        //     if(code != 0){
        //         that.props.history.replace('/login')
        //     }
        // }
        
    }

    toSearchPage = () => {
        this.props.history.push('/search')
    }

    componentDidMount(){
        // const _id = Cookie.get("_id")
        const {user} = this.props

        console.log(user)

        const token = localStorage.getItem('loginToken')            //新增token检测

        // jwt.verify()

        // if((_id || token) && !user._id){
        //     this.props.getUser()            //cookie有id信息，但redux状态还没有用户信息，则提交请求获取用户信息
        // }

        // var that = this

        if(!user._id){
            console.log('wtf')
            // this.props.getUser()            //cookie有id信息，但redux状态还没有用户信息，则提交请求获取用户信息

            // async function niko(){              //之前用cookie检测登录状态，现在换成token，写了个异步函数，若redux不包含用户信息，则发送headers带token的请求，后端校验token有效则返回code==0，其他值则触发重定向
            //     var code = await that.props.getUser()   //用withRouter组件实现重定向（具体操作可以看nav-footer.jsx）
            //     console.log(code)
            //     if(code != 0){
            //         // location.href = "/login"
            //         that.props.history.replace('/login')
            //     }
            // }
            
            // niko()
            this.sendToken()
        }

        console.log(token)
    }

    componentDidUpdate(){
        console.log('main updated')
        console.log(this.props.user._id)
        if(!this.props.user._id){
            this.props.history.replace('/login')
        }
    }

    render(){
        //自动登录功能实现（检测到有cookie存在则自动登录）
        const {user,unreadCount} = this.props
        // const _id = Cookie.get("_id")
        // if(!_id){
        //     return <Redirect to="/login"/>                      //无cookie，跳转至登录界面
        // }

        // if(!_id){
        //     return <Redirect to="/login"/>                      //无cookie，跳转至登录界面
        // }

        var path = this.props.location.pathname                 //组件包含当前路径信息
        if(!user._id){                                          //之前检测cookie，现在先等componentDidMount发送带token的请求，页面先返回空值。请求成功则渲染，失败则重定向            //有cookie，但redux状态无用户信息 
            // if(path === "/"){ 
            //     path = "login"                                  //未登录，跳转至登录页
            //     return <Redirect to={path}/>
            // }
            return null                                         //已登录，则先返回空页面，等状态更新触发渲染，会执行下面else语句
        }
        else{
            if(path === "/"){                                   //路径为空则计算跳转路径
                path = getRedirection(user.usertype,user.avatarName)
                return <Redirect to={path}/>
            }
        }

        let {navList} = this
        var path = this.props.location.pathname
        const currNav = navList.find(nav => nav.path === path)  //读取当前路径，与四个路由路径匹配，判断当前是不是在其中一个路由内
        // if(currNav){                                            //有不是的情况，比如employeeinfo和bossinfo页面，则不用进行下面的判断
        //     if(this.props.user.usertype === "employee"){        //根据用户类型，给老板或求职者对象加hidden属性
        //         navList[0].hidden = true
        //     }
        //     else{
        //         navList[1].hidden = true
        //     }
        // }
        // console.log('as')
        // console.log(navList)

        return (
            <div>
                {currNav?(
                    <NavBar 
                        style={{height:'55px'}} 
                        className="sticky-header"
                        rightContent={(
                            <Badge text={this.props.user.friendReqs.length > 0 ? '!' : ''}>
                                <Icon type="search" onClick={()=>{this.props.history.push('/search')}}/>
                            </Badge>
                        )}
                    >
                        {currNav.title}
                    </NavBar>
                ):null} 
                <Switch>
                    {navList.map((nav,index) => <Route path={nav.path} component={nav.component} key={index}/>)}
                    <Route path="/bossinfo" component={BossInfo} key="bossinfo"/>
                    <Route path="/employeeinfo" component={EmployeeInfo} key="employeeinfo"/>
                    <Route path="/chat/:userid" component={Chat} key="chat"/>
                    <Route path="/search" component={AddFriend} key="addfriend"/>
                </Switch>
                {currNav?<NavFooter unreadCount={unreadCount} navList={navList}/>:null}
            </div>
        )
        //路径斜杠加冒号加变量可以传变量给该路由
        //具体说明待完善
    }
}

export default withRouter(connect(
    sta => ({user:sta.user,unreadCount:sta.chatMsg.unreadCount}),
    {getUser}
)(Main)) 