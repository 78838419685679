import ajax from "./ajax"

import {serverAddress} from "../utils/index"
import axios from "axios"

//提供自定义API，所有项目内文件均可使用
export const reqRegister = (user) => ajax('/register',user,'POST')
export const reqLogin = (user) => ajax('/login',user,'POST')
export const reqUpdateUser = (user) => ajax('/update',user,'POST')
export const reqUser = () => ajax('/user')
export const reqUserList = (usertype) => ajax('/userlist',{usertype})     //默认用GET方法，可不填
export const reqMsgList = () => ajax('/messageList')
export const reqReadMsg = (from) => ajax('/readMessage',{from},'POST')

export const reqUserList2 = (users) => ajax('/userlist2',{users},'POST')    //新的获取用户列表方法，users是数组，包含所有要返回的用户详情信息
export const reqSearchFriend = (friend) => ajax('/searchFriend',{friend})
export const reqAddFriend = (friend) => ajax('/addFriend',{friend},'POST')
export const reqAcceptFriend = (friend) => ajax('/acceptFriend',{friend},'POST')
export const reqRefuseFriend = (friend) => ajax('/refuseFriend',{friend},'POST')
export const refreshToken = () => axios.get('http://' + serverAddress + '/refreshToken',{headers:{'refreshToken':localStorage.getItem('refreshToken')}})

export const upload = (data) => axios.post('/upload',data,{headers:{'Content-Type':'multipart/form-data'}})