import React,{Component} from "react"
import {connect} from "react-redux"
import {Result,WhiteSpace,WingBlank,List,Button,Modal} from "antd-mobile"
import Cookies from "js-cookie"

import {resetUser} from '../../redux/actions'

const {Item} = List
const {Brief} = Item

class Personal extends Component{
    logOut = ()=>{                                  //退出登录，执行删除用户操作
        Modal.alert('Log out','R U sure to log out?',[
            {
                text:'Cancel'
            },
            {
                text:'OK',
                onPress: ()=>{
                    localStorage.setItem('loginToken',null)
                    localStorage.setItem('refreshToken',null)
                    Cookies.remove('_id')           //cookie删除存储的id
                    this.props.resetUser()          //redux状态删除用户信息
                }
            }
        ])
    }

    render(){
        console.log(this.props.user)
        const {userid,usertype,avatarName,post,intro,salary} = this.props.user
        console.log(usertype)

        return (
            <div  style={{marginTop:'55px',marginBottom:'80px'}}>
                <Result
                    img={<img src={require(`../../assets/icon/${avatarName}.png`)} style={{width:'60px',height:'60px'}}/>}
                    title={userid}
                    message={usertype}
                />
                <WhiteSpace/>
                <WingBlank>
                    <List renderHeader={()=>'Information'}>
                        <Item>
                            {/* <Brief>Post: {post}</Brief> */}
                            <Brief>Intro: {intro}</Brief>
                            {/* <Brief>Salary: {salary}</Brief> */}
                        </Item>
                    </List>
                    <WhiteSpace/><WhiteSpace/><WhiteSpace/>
                    <Button type="primary" onClick={() => {this.props.history.push('/bossinfo')}}>Edit personal data</Button>
                    <WhiteSpace/>
                    <Button type="warning" onClick={this.logOut}>Log out</Button>
                </WingBlank>
            </div>
        )
    }
}

export default connect(
    sta=>(sta),
    {resetUser}
)(Personal)