import React,{Component} from "react"
import {connect} from "react-redux"
import {sendMsg,readMsg} from "../../redux/actions"
import {NavBar, List, InputItem, Grid, Icon, TextareaItem, Button} from "antd-mobile"

const {Item} = List
const {Brief} = Item

class Chat extends Component{
    state = {
        content: '',
        emojisShow: false,
        listHeight: '0',         //记录消息列表高度，一旦有改动就是收到新消息，触发定位至底部
        listNum: 0,
        textHeight: 150
        // chatHeight: window.screen.height - 100 + 'px'
    }

    sendBtn = () => {
        var from = this.props.user._id
        var to = this.props.match.params.userid             //通过路由传进来的值userid在这个位置
        var content = this.state.content.trim()             //此处content更新会触发状态更新从而执行componentDidUpdate，触发画面滚动至最底部，所以把content拿出来，不放在state里
        if(content){
            // console.log(this.props)
            this.props.sendMsg({from,to,content})
            if(this.state.emojisShow){               //发送消息时，如果emoji框在展示，则隐藏
                this.showEmojis()
            }
        }
        this.setState({content:''})
        
    }

    showEmojis = () => {
        
        var emojisShow = !this.state.emojisShow
        console.log(emojisShow)
        this.setState({emojisShow})
        console.log(document.getElementById('listwindow').style.height)
        if(emojisShow){
            document.getElementById('listwindow').style.height = window.screen.height - this.textHeight + 25 + 'px'
            document.getElementById('listwindow').scrollTop += 150
            setTimeout(()=>{
                window.dispatchEvent(new Event('resize'))
            },0)
        }else{
            document.getElementById('listwindow').style.height = '100%'
        }
    }

    scrollToBottom = () => {
        var listwindow = document.getElementById('listwindow')
        // console.log(listwindow)
        if (listwindow) {
            // console.log(listwindow.scrollTop)
            
            const scrollHeight = listwindow.scrollHeight;//里面div的实际高度  2000px
            const height = listwindow.clientHeight;  //网页可见高度  200px
            const maxScrollTop = scrollHeight - height; 
            const listNum = listwindow.children[0].children.length
            console.log(scrollHeight,height,maxScrollTop,maxScrollTop > 0 ? maxScrollTop : 0)
            console.log(listwindow.children[0].children.length)

            if(listNum == this.state.listNum)   //状态更新但是消息列表高度没变，是因为只有输入框改变了，不触发定位至底部
                return
            this.setState({listNum:listNum})    //消息列表高度变了，因为接收到新消息，触发定位至底部
            // if(scrollHeight == this.state.listHeight)   //状态更新但是消息列表高度没变，是因为只有输入框改变了，不触发定位至底部
            //     return
            // this.setState({listHeight:scrollHeight})    //消息列表高度变了，因为接收到新消息，触发定位至底部

            
            listwindow.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;        //经测试edge浏览器用scrollTo会报错，猜测因为其scrollTo只能用于window，其他对象不存在该方法
            // listwindow.scrollTo(0,maxScrollTop > 0 ? maxScrollTop : 0)      //用scrollTo和scrollTop都可（scrollTop只能指定Y轴，scrollTo可以指定X、Y轴）
            console.log(scrollHeight,height,maxScrollTop,maxScrollTop > 0 ? maxScrollTop : 0)
            // console.log(listwindow.scrollTop)
          //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。

            var from = this.props.match.params.userid
            var to = this.props.user._id
            this.props.readMsg(from,to)     //把对方发给我的消息标记为已读
        }
    }

    numberToDate = (num) => {
        var date = new Date(num)
        var ima = new Date()
        var currentYear = ima.getFullYear()
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        var day = date.getDate()
        var hour = date.getHours()
        var minute = date.getMinutes().toString()
        if(minute.length < 2){
            minute = '0' + minute
        }
        
        var result = ''

        if(currentYear != year){
            result += year + '.'
        }
        result += month + '.' + day + ' ' + hour + ':' + minute
        return result
    }

    componentDidUpdate(){
        // console.log('updata')
        this.scrollToBottom()
        // setInterval(()=>{
        //     console.log(listwindow.scrollTop)
        // },1000)

        var textHeight = parseInt(document.getElementById('text').style.height.replace('px','')) 
        if(textHeight > 200){
            textHeight = 200
        }
        this.textHeight = textHeight
        console.log(textHeight)
        // this.testnum = textHeight + ' ' + window.screen.height + ' ' + document.getElementById('listwindow').style.height + ' '  + window.innerHeight
        document.getElementById('listwindow').style.height = window.innerHeight - textHeight + 25 + 'px'
    }

    componentDidMount(){
        var emojis = ['😀','😃','😄','😁','😆','😅','🤣','😂',
                    '🙂','🙃','😉','😊','😇','🥰','😍','🤩',
                    '😘','😗','🤪','😚','😙','😋','😛','😜']
        this.emojis = emojis.map(emoji => ({text: emoji}))

        var itv = setInterval(()=>{
            if(document.getElementById('listwindow')){
                var listwindow = document.getElementById('listwindow')
                console.log(document.body.clientHeight)
                clearInterval(itv)
            }
        },1000)
        

        this.scrollToBottom()
        // setInterval(()=>{
        //     console.log(this.state.content.length)
        // },1000) 
    }

    render(){
        // console.log(this.props)
        var oreId = this.props.user._id                     //我的id
        var kareId = this.props.match.params.userid         //对方的id

        if(!this.props.chatMsg.userList[oreId]){            //消息至少包含我的信息，没有则代表还没接受到消息列表，先返回空值
            return null
        }


        //返回的chatMsg中，包含已存在的和自己相关的聊天消息，和这些消息中存在的用户信息（用户名及头像）
        //于是存在一个问题，假如有一个用户未曾和我有聊天记录，则返回的用户信息没有他，此处读取也会Undefined出错
        //于是这种情况便在reducer的userList里读取该用户的信息
        //数据结构设计的有问题，可能是导师故意埋的坑，大概后面的课程会解决这个问题
        if(this.props.chatMsg.userList[kareId]){        //chatMsg能找到该用户
            var kareUserid = this.props.chatMsg.userList[kareId].userid     //对方用户名
            var kareIcon = require(`../../assets/icon/${this.props.chatMsg.userList[kareId].avatarName}.png`)
        }else{                                          //chatMsg找不到，在userList找
            var kare = this.props.userList.find(item => item._id == kareId)
            var kareUserid = kare.userid
            var kareIcon = require(`../../assets/icon/${kare.avatarName}.png`)
        }
        

        var chat_id = [oreId,kareId].sort().join('_')
        var oreIcon = require(`../../assets/icon/${this.props.chatMsg.userList[oreId].avatarName}.png`)
        var msgs = this.props.chatMsg.chatMsgs.filter(item => item.chat_id == chat_id)

        // console.log(msgs)
        // console.log(chat_id)

        return (
            <div id="chat-page">
                <NavBar 
                    leftContent={(<Icon type="left"/>)}
                    onLeftClick={()=>this.props.history.goBack()}
                    className="sticky-header"
                >
                    {kareUserid}
                    {/* {this.testnum} */}
                </NavBar>
                <List id="listwindow" style={{
                    position: 'fixed',
                    height: "100%",
                    width: '100%',
                    // top:'50px',
                    // paddingTop:'50px',
                    // paddingBottom: '100px',
                    overflow: 'scroll'}}
                >
                    {msgs.map((item,index) => {
                        // console.log(msgs)
                        // console.log(oreId)
                        var time = this.numberToDate(item.create_time)
                        var style = {}
                        if(index == 0){
                            style = {marginTop:'50px'}
                        }else if(index == msgs.length - 1){
                            style = {marginBottom:'50px'}
                        }
                        console.log(time)
                        if(item.from == oreId){
                            return (                        //我发的
                                <Item key={index}
                                    className="chat-ore"
                                    multipleLine={true}
                                    wrap={true}
                                    extra={<img src={oreIcon}/>}
                                    style={style}
                                >
                                    <div style={{paddingLeft:'20px'}}>
                                        <Brief style={{textAlign:'right'}}>{time}</Brief>
                                        <text style={{wordWrap:'break-word',float:'right',maxWidth:'94%'}}>
                                            {item.content}
                                        </text>
                                    </div>
                                </Item>
                            )
                        }else{                              //他发的
                            return (
                                <Item key={index}
                                    multipleLine={true}
                                    wrap={true}
                                    thumb={kareIcon}
                                    style={style}
                                >
                                    <Brief>{time}</Brief>
                                    {item.content}
                                </Item>
                            )
                        }
                    })}
                    {/* <Item thumb={require(`../../assets/icon/01.png`)}>
                        bonjour
                    </Item>
                    <Item 
                        className="chat-ore"
                        extra={<img src={require(`../../assets/icon/04.png`)}/>}
                    >
                        konnichiwa
                    </Item> */}
                </List>
                <div className="send-bar">
                    {/* <InputItem
                        // maxLength={23}
                        placeholder="届けるのか？"
                        value={this.state.content}
                        onChange={val => this.setState({content:val})}
                        extra={
                            <div>
                                <span onClick={this.showEmojis}>🙃</span>
                                <span onClick={this.sendBtn}>
                                    届け
                                </span>
                            </div>
                        }
                    /> */}
                    <div style={{display:'flex'}}>
                        <div style={{width:'77%'}}>
                            <TextareaItem
                                placeholder="届けるのか？"
                                value={this.state.content}
                                onChange={val => this.setState({content:val})}
                                autoHeight
                                style={{border:'1px solid #AAAAAA',borderRadius:'5px',width:'96%',maxHeight:'200px'}}
                                id="text"
                            />
                        </div>
                        <div style={{width:'23%',display:'flex',alignItems:'center',backgroundColor:'#FFFFFF',paddingRight:'5px'}}>
                            <div style={{width:'30%'}}>
                                <span onClick={this.showEmojis}>🙃</span>
                            </div>
                            <div style={{width:'70%'}}>
                                <Button type="primary" size="small" onClick={this.sendBtn} style={{width:'auto'}}>
                                    届け
                                </Button>
                            </div>
                        </div>
                        
                    </div>
                    
                    {this.state.emojisShow?(
                        <Grid
                            data={this.emojis}
                            columnNum={8}
                            isCarousel={true}
                            carouselMaxRow={3}
                            onClick={(item)=>{
                                if(this.state.content.length < 22){      //添加表情属于直接给content加内容，不会受到输入框输入上限的限制，在这里手动做限制
                                    this.setState({content:this.state.content + item.text})
                                }
                            }}
                            style={{paddingBottom:'10px',height:'150px'}}
                        >
                        </Grid>
                    ):null}
                </div>
                
                
            </div>
        )
    }
}

export default connect(
    sta=>(sta),
    {sendMsg,readMsg}
)(Chat)

// var listwindow = document.getElementById('listwindow')      //需要滚动的对象
// const scrollHeight = listwindow.scrollHeight;               //里面div的实际高度
// const height = listwindow.clientHeight;                     //网页可见高度
// const maxScrollTop = scrollHeight - height;                 //需要滚动的距离
// listwindow.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0; //经测试edge浏览器用scrollTo会报错，猜测因为其scrollTo只能用于window，其他对象不存在该方法