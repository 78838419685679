import "babel-polyfill"
import 'core-js/es'  
import 'react-app-polyfill/ie9'  
import 'react-app-polyfill/stable'

import React from 'react'
import {render} from 'react-dom'
import {Button} from 'antd-mobile'
import {HashRouter,Route,Switch} from 'react-router-dom'
import {Provider} from "react-redux"


import store from "./redux/store"
import Register from './containers/register/register'
import Login from './containers/login/login'
import Main from './containers/main/main'
import Upload from './containers/upload/upload'

// import "./test/socketio_demo"

import "./assets/css/index.less"

render((
    <Provider store={store}>
        <HashRouter>
            <Switch>
                <Route path="/register" component={Register}/>
                <Route path="/login" component={Login}/>
                <Route path="/upload" component={Upload}/>
                <Route component={Main}/>
            </Switch>
        </HashRouter>
    </Provider>
),document.getElementById('root'))

// import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'
// var comDir = ''
// var cssDir = ''

// function importGuys(arr){
//   // for(item in arr){
//   //   var comDir = 'import ' + item + ' from antd-mobile/lib/' + item
//   //   var cssDir = 'import antd-mobile/lib/' + item +'/style/css'
//   //   eval(comDir)
//   //   eval(cssDir)
//   // }
//   var code = ''
//   arr.map((item,index)=>{
//     code += 'import ' + item + ' from "antd-mobile/lib/' + item + '"; import "antd-mobile/lib/' + item +'/style/css"; '
//     // eval(comDir)
//     // eval(cssDir)
//     console.log(code)
//   })
//   return code
// }

// eval(importGuys(['button']))



