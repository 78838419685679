/*
 注册路由组件
*/

import React,{Component} from "react"
import {connect} from "react-redux"
import {NavBar,WingBlank,List,InputItem,WhiteSpace,Radio,Button} from 'antd-mobile'

import Logo from '../../components/logo/logo' 
import { Redirect } from "react-router-dom"

import {register} from "../../redux/actions"

const Item = List.Item

class Register extends Component{
    state = {
        userid: '',
        password:'',
        password2:'',
        usertype:'boss'
    }

    dataChange = (type,data)=>{
        this.setState({[type]:data})            //用中括号[]包裹，里面的内容则变为变量值，否则为字符串
    }

    signUp = ()=>{
        // console.log(this.state)
        this.props.register(this.state)
    }

    toSignIn = ()=>{
        this.props.history.replace('/login')       //push：跳转后可点击上一页按钮返回上个界面;replace:替换掉当前页面，返回不了
    }
    
    render(){
        const {msg,redirectTo} = this.props
        if(redirectTo){                            //检测重定向是否存在，存在则直接跳转，下面的渲染不执行
            return <Redirect to={redirectTo}/>
        }

        return (
            <div>
                <NavBar>niko chat</NavBar>
                <WhiteSpace/>
                <Logo/>
                <WhiteSpace/>
                {/* 显示提示信息，没有则不显示 */}
                {msg?<div>{msg}</div>:null}
                <WingBlank>
                    <List>
                        <InputItem onChange={value=>{this.dataChange('userid',value)}} labelNumber={7} placeholder="your id">User ID</InputItem>
                        <InputItem onChange={value=>{this.dataChange('password',value)}} labelNumber={7} placeholder="your password" type="password">Password:</InputItem>
                        <InputItem onChange={value=>{this.dataChange('password2',value)}} labelNumber={7} placeholder="type your password again" type="password">Confirm pwd:</InputItem>
                        {/* <Item>
                            <span>User type:</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Radio checked={this.state.usertype === 'employee'} onChange={e=>this.dataChange('usertype','employee')}>Employee</Radio>
                            &nbsp;&nbsp;&nbsp;
                            <Radio checked={this.state.usertype === 'boss'} onChange={e=>this.dataChange('usertype','boss')}>Boss</Radio>
                        </Item> */}
                    </List>
                    <WhiteSpace/><WhiteSpace/>
                    <Button onClick={this.signUp} type="primary">Sign up</Button>
                    <Button onClick={this.toSignIn}>Already have account</Button>
                </WingBlank>
            </div>
        )
    }
}

export default connect(
    sta=>(sta.user),
    {register}
)(Register)