import React from 'react'

import './logo.less'
import logo from './icon.png'

export default function Logo(){
    return (
        <div className="logo-container">
            <img src={logo} alt="icon" className="logo-img"/>
        </div>
    )
}