import {combineReducers} from "redux"

import {AUTH_SUCCESS,ERROR_MSG,RECEIVE_USER,RESET_USER,RECEIVE_USERLIST,RECEIVE_CHATLIST,RECEIVE_CHAT,READ_MSG} from "./action-types"
import {getRedirection} from "../utils/index"

const initUser = {
    userid:'',
    usertype:'',
    msg:'',
    redirectTo:'',
    friendReqs:[],
    friendList:[]
}

//用户个人信息
function user(state=initUser,action){
    switch(action.type){
        case AUTH_SUCCESS:
            const {usertype,avatarName} = action.data
            console.log('1')
            console.log(getRedirection(usertype,avatarName))
            return {...action.data,redirectTo:getRedirection(usertype,avatarName)}

        case ERROR_MSG:
            console.log('2')
            return {...state,msg:action.data}

        case RECEIVE_USER:
            console.log('3')
            return action.data

        case RESET_USER:
            console.log('4')
            return {...initUser,msg:action.data}

        default:
            console.log('0')
            return state
    }
}

//与用户相反类型（boss or employee)的所有用户列表
const initUserList = []
function userList(state=initUserList,action){
    switch(action.type){
        case RECEIVE_USERLIST:
            return action.data

        default:
            return state
    }
}

//聊天信息，包括聊天记录、聊天记录出现过的用户的图标、
const initChatMsg = {
    userList:{},
    chatMsgs:[],
    unreadNum: 0
}
function chatMsg(state = initChatMsg,action){
    switch(action.type){
        case RECEIVE_CHATLIST:
            var {userList,chatMsgs,oreId} = action.data
            return {userList,chatMsgs,unreadCount: chatMsgs.reduce((total,msg)=>total+(msg.unread&&msg.to==oreId?1:0),0)}

        case RECEIVE_CHAT:
            var {userList,chatMsgs} = state
            var {chatMsg,oreId} = action.data
            chatMsgs[chatMsgs.length] = chatMsg
            return {userList,chatMsgs,unreadCount: state.unreadCount + (chatMsg.unread&&chatMsg.to==oreId?1:0)}

        case READ_MSG:
            var {count,from,to} = action.data
            var {userList,chatMsgs} = state
            var newChatMsgs = chatMsgs.map(msg => {
                if(msg.from == from && msg.to == to && msg.unread){
                    return {...msg, unread: false}
                }else{
                    return msg
                }
            })
            return {userList, chatMsgs: newChatMsgs, unreadCount: state.unreadCount - count}

        default:
            return state
    }
}

//多个reducers合并
export default combineReducers({
    user,
    userList,
    chatMsg
})