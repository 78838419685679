import React,{Component} from "react"
import {connect} from "react-redux"

import {NavBar,Icon,SearchBar,Card,Button,ActivityIndicator,Toast} from "antd-mobile"

import {reqAddFriend,reqSearchFriend,reqUserList2,reqAcceptFriend,reqRefuseFriend} from "../../api/index"
import {getUser} from "../../redux/actions"

const {Header,Body,Footer} = Card

class AddFriend extends Component{
    state = {
        search: '',      //要查找的用户名userid
        friend: null,
        friendReqs: this.props.user.friendReqs,       //收到的好友请求
        friendReqsList: [],
        loading: false,
        btnDisabled: false
    }

    goSearch = async () => {
        this.setState({loading:true})
        reqSearchFriend(this.state.search).then((response) => {
            this.setState({loading:false})
            const result = response.data
            if(result.code == 0){       //获取成功
                if(result.data.friend){
                    this.setState({friend:result.data.friend,btnDisabled:false})
                }
            }else{
                Toast.info(result.data,3,null,false)
            }
        }).catch((err)=>{
            this.setState({loading:false})
            console.log(err)
        })
        // const response = await reqSearchFriend(this.state.search)
        // this.setState({loading:false})
        // const result = response.data
        // if(result.code == 0){       //获取成功
        //     if(result.friend){
        //         this.setState({friend:result.friend})
        //     }
        // }
    }

    addFriend = () => {
        reqAddFriend(this.state.friend.userid).then((response)=>{
            let result = response.data
            if(result.code == 0){
                Toast.info(result.data,3,null,false)
                this.setState({btnDisabled:true})
            }else{
                Toast.info(result.data,3,null,false)
            }
        }).catch((err)=>{
            Toast.info('Error,sent to server failed',3,null,false)
        })
    }

    accept = async (userid) => {
        const resource = await reqAcceptFriend(userid)
        const result = resource.data
        if(result.code == 0){
            Toast.info(result.data,3,null,false)
            await this.refreshReqs()
            this.getReqList()
        }
    }

    refuse = async (userid) => {
        const resource = await reqRefuseFriend(userid)
        const result = resource.data
        if(result.code == 0){
            Toast.info(result.data,3,null,false)
            await this.refreshReqs()
            this.getReqList()
        }
    }

    refreshReqs = async() => {                      //刷新好友请求列表
        await this.props.getUser()
        this.setState({friendReqs: this.props.user.friendReqs})
    }

    getReqList = async () => {
        console.log(this.state.friendReqs)
        const resource = await reqUserList2(this.state.friendReqs)
        const result = resource.data
        this.setState({friendReqsList:result.data})
        console.log(this.state.friendReqsList)
    }

    componentDidMount = async () => {
        await this.refreshReqs()
        this.getReqList()
    }

    render(){
        const {friend,friendReqsList} = this.state

        return (
            <div>
                <NavBar 
                    leftContent={(<Icon type="left"/>)}
                    onLeftClick={()=>this.props.history.goBack()}
                    className="sticky-header"
                >
                    Search user
                </NavBar>
                <SearchBar
                    placeholder="who u wanna add"
                    showCancelButton
                    cancelText="Search"
                    onCancel={this.goSearch}
                    onChange={val => this.setState({search:val})}
                    style={{marginTop:'50px'}}
                />
                {friend ? (
                    <Card>
                        <Header 
                            thumb = {require('../../assets/icon/'+ friend.avatarName + '.png')}
                            thumbStyle = {{width:'15vw'}}
                            extra = {friend.userid}
                        />
                        <Body>
                            {friend.intro? <div>Intro: {friend.intro}</div> : null}
                        </Body>
                        <Footer extra={<Button disabled={this.state.btnDisabled} type="primary" onClick={this.addFriend}>Add friend</Button>}/>
                    </Card>
                ):null}

                {friendReqsList.length > 0 ? <div style={{padding:'12px',color:'#666666',fontSize:'20px'}}>Friend requests:</div>:null}
                {friendReqsList.length > 0 ? 
                    friendReqsList.map(item => (
                        <Card>
                            <Header 
                                thumb = {require('../../assets/icon/'+ item.avatarName + '.png')}
                                thumbStyle = {{width:'15vw'}}
                                extra = {item.userid}
                            />
                            <Body>
                                {item.intro? <div>Intro: {item.intro}</div> : null}
                            </Body>
                            <Footer 
                                extra={
                                    <div>
                                        <Button size="small" type="warning" inline onClick={this.refuse.bind(this,item.userid)}>Refuse</Button>&nbsp;&nbsp;&nbsp;
                                        <Button size="small" type="primary" inline onClick={this.accept.bind(this,item.userid)}>Accept</Button>
                                    </div>
                                }
                            />
                        </Card>
                    ))
                
                    
                :null}

                <ActivityIndicator
                    toast
                    animating={this.state.loading}
                ></ActivityIndicator>
                
            </div>
        )
    }
}

export default connect(
    sta=>({user:sta.user}),
    {getUser}
)(AddFriend)