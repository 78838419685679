export function getRedirection(usertype,avatar){
    let path = ''
    if(usertype === 'employee'){
        // path = 'employee'
        path = 'boss'
    }
    else{
        path = 'boss'
    }
    
    if(!avatar){
        path += 'info'
    }

    return path
}

// export const serverAddress = 'localhost:8686'
// export const serverAddress = '8.129.213.176:8686'
export const serverAddress = 'ppniko.com:8686'