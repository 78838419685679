import io from "socket.io-client"

import {serverAddress} from "../utils/index"
// import { resolve } from "core-js/fn/promise"
import {reqLogin,reqRegister,reqUpdateUser,reqUser,reqUserList,reqMsgList,reqReadMsg,reqUserList2} from "../api/index"
// import chat from "../containers/chat/chat"
import {AUTH_SUCCESS,ERROR_MSG,RECEIVE_USER,RESET_USER,RECEIVE_USERLIST,RECEIVE_CHATLIST,RECEIVE_CHAT,READ_MSG} from "./action-types"

const authSuccess = (user) => ({type:AUTH_SUCCESS,data:user})
const errorMsg = (msg) => ({type:ERROR_MSG,data:msg})
const receiveUser = (user) => ({type:RECEIVE_USER,data:user})       //更新资料成功
export const resetUser = (msg) => ({type:RESET_USER,data:msg})             //更新资料失败，用户状态重置
const receiveUserList = (userlist) => ({type:RECEIVE_USERLIST,data:userlist})
const receiveChatList = (chatlist) => ({type:RECEIVE_CHATLIST,data:chatlist})
const receiveChat = (chat) => ({type:RECEIVE_CHAT,data:chat})
const receiveReadMsg = ({count,from,to}) => ({type:READ_MSG,data:{count,from,to}})

export const register = (user) => {
    //前台校验，若输入值有问题，则返回同步操作
    if(!user.userid){
        return errorMsg('Enter userid pls')
    }
    if(!user.password){
        return errorMsg('Enter password pls')
    }
    if(user.password !== user.password2){
        return errorMsg('2 passwords are different')
    }
    // if(!user.usertype){
    //     return errorMsg('Choose user type pls')
    // }

    //表单校验没问题，则进行异步操作
    return async dispatch => {                      //将该箭头函数设置为异步函数，里面的内容才能用await语法
        const resource = await reqRegister(user)    //await等待的运算结果若不是promise对象，则返回值就是运算结果；此处运算结果为promise对象，则直接把promise的resolve值（返回值）提取出来（传统方法要在then的回调函数中获取返回值）
        const result = resource.data                //axios返回的promise对象的传出参数是对象，把http请求的结果封在data属性里，这里把结果取出来
        //axios请求返回所需的对象在属性data中，这里提取至result。此处对象有两种（后台自定义）：{code:0,data:doc}或{code:1,msg:"Userid or password's wrong"}
        console.log('a')
        console.log(resource)
        if(result.code === 0){                      //注册成功
            localStorage.setItem('loginToken',result.token)
            localStorage.setItem('refreshToken',result.refreshToken)
            // initSocket(dispatch,result.data._id)                      //登陆成功，用id连接socket
            // getMsgList(dispatch)                             //登录后获取消息列表
            initData(dispatch,result.data._id)              //登录或刷新统一进行该操作
            dispatch(authSuccess(result.data))
        }
        else{
            dispatch(errorMsg(result.msg))
        }
    }
}

export const login = (user) => {
    //前台校验，若输入值有问题，则返回同步操作
    if(!user.userid){
        return errorMsg('Enter userid pls')
    }
    if(!user.password){
        return errorMsg('Enter password pls')
    }

    //表单校验没问题，则进行异步操作
    return async dispatch => {
        const resource = await reqLogin(user)
        console.log(resource)
        const result = resource.data                //axios返回的promise对象的传出参数是对象，把http请求的结果封在data属性里，这里把结果取出来
        //返回数据对象：{code:0,data:doc}或{code:1,msg:"Userid or password's wrong"}
        console.log('b')
        console.log(resource)
        if(result.code === 0){                      //登录成功
            localStorage.setItem('loginToken',result.token)
            localStorage.setItem('refreshToken',result.refreshToken)
            // initSocket(dispatch,result.data._id)                      //登陆成功，用id连接socket
            // getMsgList(dispatch)                             //登录后获取消息列表
            initData(dispatch,result.data._id)              //登录或刷新统一进行该操作
            dispatch(authSuccess(result.data))
        }
        else{
            dispatch(errorMsg(result.msg))
        }
        
    }
}

export const updateUser = (user) => {
    return async dispatch => {
        const resource = await reqUpdateUser(user)
        const result = resource.data
        if(result.code === 0){
            dispatch(receiveUser(result.data))
        }
        else{
            dispatch(resetUser(result.msg))
        }
    }
}

export const getUser = () => {
    return async dispatch => {
        const resource = await reqUser()
        console.log(resource)
        const result = resource.data
        if(result.code === 0){
            // initSocket(dispatch,result.data._id)                      //登陆成功，用id连接socket
            // getMsgList(dispatch)                             //登录后获取消息列表
            initData(dispatch,result.data._id)              //登录或刷新统一进行该操作
            dispatch(receiveUser(result.data))
        }
        else{
            dispatch(resetUser(result.msg))
        }
        return result.code
    }
}

export const getUserList = (users) => {
    return async dispatch => {
        const resource = await reqUserList2(users)      //新方法，发送用户数组，返回每个用户的详情信息
        const result = resource.data
        if(result.code === 0){      //玩nm，现在交互接口默认先校验token，过期返回233    //后端我自己写的，只可能返回零，判断着玩
            dispatch(receiveUserList(result.data))
        }
        else{
            dispatch(resetUser(result.msg))
        }
    }
}

// export const getUserList = (usertype) => {
//     return async dispatch => {
//         const resource = await reqUserList(usertype)
//         const result = resource.data
//         if(result.code === 0){      //玩nm，现在交互接口默认先校验token，过期返回233    //后端我自己写的，只可能返回零，判断着玩
//             dispatch(receiveUserList(result.data))
//         }
//         else{
//             dispatch(resetUser(result.msg))
//         }
//     }
// }

export function getSth(){
    var wsAddress = "ws://" + serverAddress         //地址头用ws,代表使用websocket协议，后接服务器地址
    io.socket = io(wsAddress)
    io.socket = null
}

function initSocket(dispatch,id){                       //初始化连接socket（只执行一次），加入在线列表，消息只会单独发给本人
    if(!io.socket){
        var wsAddress = "ws://" + serverAddress         //地址头用ws,代表使用websocket协议，后接服务器地址
        io.socket = io(wsAddress)                       //连接至服务器
        io.socket.on('clientReceived',function(data){   //绑定监听事件，实时收取服务器发送的数据
            console.log('client received from server')
            console.log(data)
            dispatch(receiveChat({chatMsg:data,oreId:id}))                 //收到消息后客户端进行处理
        })
        io.socket.emit('joinChat',id)                   //发送加入聊天室事件，将自定义id传入，服务端会记录并绑定一个socket id
    }                                                   //之后有发给该id的消息，服务端会传给对应的socket id
}

export const sendMsg = ({from,to,content}) => {         //点击发送按钮触发发送
    return async dispatch => {
        console.log('client sent to server')
        console.log({from,to,content})
        io.socket.emit('clientSent',{from,to,content})  //发送发消息事件，将封装好的消息发送给服务器
    }
}

export const readMsg = (from,to) => {
    return async dispatch => {
        const resource = await reqReadMsg(from)
        const result = resource.data
        if(result.code == 0){
            dispatch(receiveReadMsg({count:result.data,from,to}))
        }
    }
}

async function getMsgList(dispatch,oreId) {               //获取跟自己相关的消息列表
    const resource = await reqMsgList()
    const result = resource.data
    dispatch(receiveChatList({...result.data,oreId}))
}

var initTimeout
function initData(dispatch,oreId){              //登陆成功或刷新页面时统一进行该函数内一系列操作
    initSocket(dispatch,oreId)                  //登陆成功，用id连接socket
    getMsgList(dispatch,oreId)                  //登录后获取消息列表
    clearTimeout(initTimeout)
    initTimeout = setTimeout(()=>{
        initData(dispatch,oreId)
    },1000*60*5)                                //每五分钟自动刷新一次
}