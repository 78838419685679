import React,{Component} from "react"
import {connect} from "react-redux"

import {List,Badge} from "antd-mobile"
import QueueAnim from 'rc-queue-anim'

const {Item} = List
const {Brief} = Item


class Message extends Component{
    getMsgList = (chatMsgs,oreId) => {
        var lastMsgList = {}

        //把每个用户的最后一条消息存入对象中
        chatMsgs.forEach(msg => {
            const chatId = msg.chat_id

            if(msg.to == oreId && msg.unread){
                msg.unreadCount = 1
            }else{
                msg.unreadCount = 0
            }

            if(!lastMsgList[chatId]){           //不存在，直接创建
                lastMsgList[chatId] = msg
            }else{                              //存在，比较消息创建时间，保留最新的
                const unreadCount = lastMsgList[chatId].unreadCount + msg.unreadCount
                if(msg.create_time > lastMsgList[chatId].create_time){
                    lastMsgList[chatId] = msg
                }
                lastMsgList[chatId].unreadCount = unreadCount
            }
        })

        var lastMsgs = Object.values(lastMsgList)

        lastMsgs.sort((d1,d2) => {
            return d2.create_time - d1.create_time
        })

        return lastMsgs
    }

    render(){
        var lastMsgs = this.getMsgList(this.props.chatMsg.chatMsgs,this.props.user._id)

        return (
            <List 
                style={{
                    position: 'fixed',
                    height:'86%',
                    width: '100%',
                    marginTop:'60px',
                    overflow: 'scroll'
                }}
            >
                <QueueAnim>
                    {lastMsgs.map(msg => {
                        var oreId = this.props.user._id
                        var kareId = msg.from == oreId? msg.to: msg.from

                        if(this.props.chatMsg.userList[kareId]){        //chatMsg能找到该用户
                            var kareUserid = this.props.chatMsg.userList[kareId].userid     //对方用户名
                            var kareIcon = require(`../../assets/icon/${this.props.chatMsg.userList[kareId].avatarName}.png`)
                        }else{                                          //chatMsg找不到，在userList找
                            var kare = this.props.userList.find(item => item._id == kareId)
                            var kareUserid = kare.userid
                            var kareIcon = require(`../../assets/icon/${kare.avatarName}.png`)
                        }

                        return (
                            <Item
                                key={msg.chat_id}
                                thumb={kareIcon}
                                extra={<Badge text={msg.unreadCount}/>}
                                onClick={()=>{this.props.history.push('/chat/' + kareId)}}
                                arrow='horizontal'
                            >
                                {kareUserid}
                                <Brief>{msg.content}</Brief>
                            </Item>
                        )
                    })}
                </QueueAnim>
                
                {/* <Item>
                    
                </Item> */}
            </List>
        )
    }
}

export default connect(
    sta => sta,
)(Message)