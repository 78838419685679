import React,{Component} from "react"
import {connect} from "react-redux"
import {Redirect} from "react-router-dom"
import {NavBar,InputItem,TextareaItem,Button,Toast} from "antd-mobile"

import AvatarSelector from "../../components/avatar-selector/avatar-selector"
import {updateUser} from "../../redux/actions"

class BossInfo extends Component{
    state = {
        avatarName: this.props.user.avatarName,
        // post:'',
        // company:'',
        // salary:'',
        // requirement:''
        intro: this.props.user.intro,
        redirect: false
    }

    setAvatar = (text) => {
        this.setState({avatarName:text})
    }

    handleChange = (name,value) => {
        this.setState({[name]:value})
    }

    save = ()=>{
        // console.log(this.state)
        var that = this
        this.props.updateUser(this.state)
        if(this.state.avatarName){
            // this.redirect = true                //头像已设置，可以跳转
            Toast.success('Change successfully',2,function(){
                that.setState({redirect:true})
            })
        }
    }
    

    render(){                                          //判断是否已设置头像来确定信息完善，完善了则跳转至对应页面
        const {avatarName,usertype} = this.props.user  //该方法有待商榷，因为用户设置应该能修改信息             
        // if(avatarName){                                //这么做只能第一次完善信息时进入该页面，之后都会被强制跳转
        if(this.state.redirect){
            // const path = usertype === "boss" ? "/boss" : "/employee"
            const path = "/boss"
            return <Redirect to={path}/>
        }

        return (
            <div>
                <NavBar>Boss info</NavBar>
                <AvatarSelector setAvatar={this.setAvatar} avatarName={this.state.avatarName}/>
                {/* <InputItem placeholder="post" labelNumber={7} onChange={value=>{this.handleChange('post',value)}}>Post name:</InputItem>
                <InputItem placeholder="company" labelNumber={7} onChange={value=>{this.handleChange('company',value)}}>Company:</InputItem>
                <InputItem placeholder="money is an issue" labelNumber={7} onChange={value=>{this.handleChange('salary',value)}}>Salary:</InputItem>
                <TextareaItem placeholder="detail" labelNumber={7} rows={3} title="Requirement:" onChange={value=>{this.handleChange('requirement',value)}}></TextareaItem> */}
                <TextareaItem placeholder="detail" labelNumber={7} rows={3} title="Intro:" value={this.state.intro} onChange={value=>{this.handleChange('intro',value)}}></TextareaItem>
                <Button type="primary" onClick={this.save}>Confirm</Button>
            </div>
        )
    }
}

export default connect(
    sta=>({user:sta.user}),
    {updateUser}
)(BossInfo)