import React,{Component} from "react"
import {List,Grid,WingBlank} from "antd-mobile"

export default class AvatorSelector extends Component{
    //webpack打包支持es6模块化，也支持commonjs模块化
    constructor(props){
        super(props)
        this.avatarList = []
        for(var i = 0; i < 4; i++){
            this.avatarList.push({
                text: '0' + (i+1),
                icon: require(`../../assets/icon/0${i+1}.png`)
            })
        }
    }

    state = {
        avatar: require(`../../assets/icon/${this.props.avatarName}.png`)
    }

    selected = (el,i) => {
        // e.target.style += "background:red"
        // console.log(e.target)
        // console.log(document.getElementById('niko').style)
        for(let j = 0; j < 4; j++){
            if(j === i)
                document.getElementById('niko'+j).style.background = 'cornflowerblue'
            else
                document.getElementById('niko'+j).style.background = ''
        }
        this.setState({avatar:el.icon})
        this.props.setAvatar(el.text)
        // console.log(el)
        // console.log(i)

        console.log(this.state)
    }

    render(){
        const {avatar} = this.state
        const listHeader = !avatar ? 'Select your avator' : (
            <div>
                Your avatar:&nbsp;<img src={avatar} style={{height:'60px'}}/>
            </div>
        )
        var i = 0
        
        return (
            <div>
                <List renderHeader={()=>listHeader}>
                    <WingBlank>
                        <Grid data={this.avatarList} 
                        columnNum={2} 
                        onClick={this.selected} 
                        renderItem={dataItem => (
                            <div style={{padding:'13% 0'}} id={"niko" + (i<4?i++:(i=0,i++))}>
                                <img src={dataItem.icon} style={{height:"130px",overflow:"hidden"}}/>
                                <div>
                                    <span>{dataItem.text}</span>
                                </div>
                            </div>
                        )}></Grid>
                    </WingBlank>
                </List>
            </div>
        )
    }
}

//ant的grid组件不带有设置id的API，这里用了奇怪的方式给grid创建的对象赋id
//grid第一次渲染会渲染两次
//很奇怪的说法，这里具体表现为：设置了i给id赋值，i会++，但是假设i初始值为0，渲染完四个对象后，他们的id是4,5,6,7
//等redux状态改变触发重新渲染时，id则变为0,1,2,3，之后再触发渲染也是这样
//所以推测grid第一次渲染会渲染两次，导致id翻倍
//用三元运算(i<4?i++:(i=0,i++))控制id一直在0-3区间内
//三元运算false里返回值为i++,i先为0，赋完值变1