import React,{Component}  from "react"
import PropTypes from "prop-types"
import {withRouter} from "react-router-dom"
import {WhiteSpace,WingBlank,Card} from "antd-mobile"
import QueueAnim from 'rc-queue-anim'

const {Header} = Card
const {Body} = Card

class UserList extends Component {
    static propTypes = {
        userList: PropTypes.array.isRequired
    }

    render(){
        return (
            <WingBlank style={{marginTop:'55px',marginBottom:'86px'}}>
                <QueueAnim 
                    interval={200}
                >
                    {this.props.userList.length > 0 ?
                        this.props.userList.map((item,index) => 
                            <div key={index} onClick={()=>this.props.history.push(`/chat/${item._id}`)}>
                                <WhiteSpace/>
                                <Card>
                                    <Header 
                                        thumb = {require('../../assets/icon/'+ item.avatarName + '.png')}
                                        thumbStyle = {{width:'15vw'}}
                                        extra = {item.userid}
                                    />
                                    <Body>
                                        {/* {item.company? <div>Company: {item.company}</div> : null}
                                        <div>Pos: {item.post}</div> 
                                        <div>Salary: {item.salary}</div>
                                        {item.requirement? <div>Require: {item.requirement}</div> : null} */}
                                        {item.intro? <div>Intro: {item.intro}</div> : null}
                                    </Body>
                                </Card>
                            </div>
                        ) : 'Go add some friends'
                    }
                </QueueAnim>
            </WingBlank>
        )
    }
}

export default withRouter(UserList)