import React,{Component} from "react"
import {connect} from "react-redux"
import {Redirect} from "react-router-dom"
import {NavBar,InputItem,TextareaItem,Button} from "antd-mobile"

import AvatarSelector from "../../components/avatar-selector/avatar-selector"
import {updateUser} from "../../redux/actions"

class EmployeeInfo extends Component{
    state = {
        avatarName:'',
        post:'',
        intro:''
    }

    setAvatar = (text) => {
        this.setState({avatarName:text})
    }

    handleChange = (name,value) => {
        this.setState({[name]:value})
    }

    save = ()=>{
        // console.log(this.state)
        this.props.updateUser(this.state)
    }

    render(){                                          //判断是否已设置头像来确定信息完善，完善了则跳转至对应页面
        console.log(this.props)
        const {avatarName,usertype} = this.props.user  //该方法有待商榷，因为用户设置应该能修改信息             
        if(avatarName){                                //这么做只能第一次完善信息时进入该页面，之后都会被强制跳转
            const path = usertype === "boss" ? "/boss" : "/employee"
            return <Redirect to={path}/>
        }

        return (
            <div>
                <NavBar>Employee info</NavBar>
                <AvatarSelector setAvatar={this.setAvatar}/>
                <InputItem placeholder="post" labelNumber={7} onChange={value=>{this.handleChange('post',value)}}>Intended post:</InputItem>
                <TextareaItem placeholder="detail" labelNumber={7} onChange={value=>{this.handleChange('intro',value)}} rows={3} title="Self intro:"></TextareaItem>
                <Button type="primary" onClick={this.save}>Confirm</Button>
            </div>
        )
    }
}

export default connect(
    sta=>({user:sta.user}),
    {updateUser}
)(EmployeeInfo)