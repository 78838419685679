//封装ajax请求（通过axios包实现）并暴露

import axios from "axios"
import {serverAddress} from "../utils/index"
// import { reject, resolve } from "core-js/fn/promise"

export default function ajax(url,data,type="GET"){
    // axios.defaults.withCredentials = true
    console.log(url)
    console.log(data)

    var serverUrl = 'http://' + serverAddress          //服务器地址，现在用内网穿透，地址随时会变
    // var serverAddress = 'http://ac263e439e11.ngrok.io'
    
    // var axiosMethod

    function axiosMethod(){
        var token = localStorage.getItem('loginToken')

        if(type==="GET"){
            var dataUrl = ""
            var finalUrl = url
            if(data){
                Object.keys(data).forEach((index)=>{            //Object.keys将对象转为数组，然后forEach遍历该数组，读出属性名
                    dataUrl += index + "=" + data[index] + "&"
                })
            }
            if(dataUrl){                                    //如果有参数，则把最后的‘&’字符去掉，没有则不用去
                dataUrl = dataUrl.substring(0,dataUrl.lastIndexOf('&'))
                finalUrl += "?" + dataUrl
                console.log(finalUrl)
                console.log(dataUrl)
                console.log(url)
            }
            // return axios.get('http://localhost:8686' + url,{headers: {token}})
            return () => axios.get(serverUrl + finalUrl,{headers: {token}})
        }
        else{
            // return axios.post('http://localhost:8686' + url,data,{headers: {token}})
            return () => axios.post(serverUrl + url,data,{headers: {token}})
        }
    }
    


//现在发送请求都会在头部增加token，登录或注册成功后会返回登录token和刷新用token（刷新用token有效期是token的两倍）
//token过期机制：token分正常期、即将过期、已过期
//正常期：token未过期，刷新用token未过期，发送请求能通过校验正常返回数据
//即将过期：token已过期，刷新用token未过期，发送请求会提示233错误码，告知token已过期，此时会自动发送刷新token的请求（头部携带refreshToken），校验通过则返回新token（登录用和刷新用），并且再触发一次刚刚校验失败的请求
//已过期：token已过期，刷新用token已过期，会触发上一行所写的操作，不过刷新token请求校验不通过，返回错误码1，提示前端重新登陆（此时前端应重定向至登录页）
//简单来说，登录或注册后，后端返回5天的token和10天的refreshToken，在5天内再次访问都是使用token
//第6-10天时，token已过期，但refreshToken还没过期，则会去获取新的token和refreshToken，刷新本地的token
//超过10天，则需要重新登陆

    return axiosMethod()()
    .then(function(res){
        console.log(res)
        if(res.data.code != 233){               //token过期返回错误码233
            // return new Promise(function(resolve){
            //     console.log(res)
            //     resolve(res)
            // })
            console.log('here')
            return res
        }

        // var niko = async () => {
        //     return axios.get('http://localhost:8686/refreshToken',{headers:{'refreshToken':localStorage.getItem('refreshToken')}})
        //     .then(function(res2){
        //         console.log(res2)
        //         if(res2.data.code == 0){        //请求新token成功，再发送一次获取用户信息请求
        //             localStorage.setItem('loginToken',res2.data.token)
        //             localStorage.setItem('refreshToken',res2.data.refreshToken)
        //             return new Promise(function(resolve){
        //                 var niko = axiosMethod()
        //                 .then(function(res2){
        //                     return new Promise(function(resolve){
        //                         console.log(res2)
        //                         resolve(res2)
        //                     })
        //                 })
        //                 resolve(niko)
        //             }) 
        //         }else{                          //请求新token失败，返回登录页面
        //             console.log(res)
        //             return new Promise(function(resolve){
        //                 resolve(res)
        //             })
        //         }
        //     })
        //     // console.log(final)
        //     // resolve(final)
        // }

        // return niko()



        return new Promise(function(resolve){
            var final =
            axios.get('http://' + serverAddress + '/refreshToken',{headers:{'refreshToken':localStorage.getItem('refreshToken')}})
            .then(function(res2){
                console.log(res2)
                if(res2.data.code == 0){        //请求新token成功，再发送一次获取用户信息请求
                    localStorage.setItem('loginToken',res2.data.token)
                    localStorage.setItem('refreshToken',res2.data.refreshToken)
                    return new Promise(function(resolve){
                        // var niko = axiosMethod()
                        axiosMethod()()
                        .then(function(res){
                            // return new Promise(function(resolve){
                            //     console.log(res2)
                                resolve(res)
                            // })
                        })
                        // resolve(niko)
                    }) 
                }else{                          //请求新token失败，返回请求失败的信息，返回登录页面
                    // that.props.history.replace('/login')
                    console.log(res)
                    // return new Promise(function(resolve){
                    //     resolve(res)
                    // })
                    // return Promise.resolve(res)
                    // Promise.resolve(res)
                    return res
                }
            })
            console.log(final)
            resolve(final)
        })
            // return new Promise(function(resolve){
            //     setTimeout(function(){
            //         resolve('ahon')
            //     },2000)
                
            // })

            // return final
        //     )
        // })
    }).then(function(res){
        console.log('mark')
        console.log(res)
        return res
    })
}