/*
 登录路由组件
*/
import React,{Component} from "react"
import {connect} from "react-redux"
import {NavBar,WingBlank,List,InputItem,WhiteSpace,Radio,Button} from 'antd-mobile'

import {login,getSth} from "../../redux/actions"
import Logo from '../../components/logo/logo' 
import { Redirect } from "react-router-dom"
const Item = List.Item

class Login extends Component{
    state = {
        userid: '',
        password:'',
    }

    dataChange = (type,data)=>{
        this.setState({[type]:data})            //用中括号[]包裹，里面的内容则变为变量值，否则为字符串
    }

    signIn = ()=>{
        // console.log(this.state)
        this.props.login(this.state)
    }

    toSignUp = ()=>{
        this.props.history.replace('/register')       //push：跳转后可点击上一页按钮返回上个界面;replace：替换掉当前页面，返回不了
    }

    componentDidMount() {
        getSth()
    }
    
    render(){
        const {msg,redirectTo} = this.props
        if(redirectTo){                               //检测重定向是否存在，存在则直接跳转，下面的渲染不执行
            return <Redirect to={redirectTo}/>
        }
        
        return (
            <div>
                <NavBar>niko chat</NavBar>
                <WhiteSpace/>
                <Logo/>
                <WhiteSpace/>
                <WingBlank>
                    {msg?<div>{msg}</div>:null}
                    <List>
                        <InputItem onChange={value=>{this.dataChange('userid',value)}} labelNumber={7} placeholder="your id">User ID</InputItem>
                        <InputItem onChange={value=>{this.dataChange('password',value)}} labelNumber={7} placeholder="your password" type="password">Password:</InputItem>
                    </List>
                    <WhiteSpace/><WhiteSpace/>
                    <Button onClick={this.signIn} type="primary">Sign in</Button>
                    <Button onClick={this.toSignUp}>Have no account</Button>
                </WingBlank>
            </div>
        )
    }
}

export default connect(
    sta=>(sta.user),
    {login}
)(Login)